import React, { useState, useEffect, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import styles from "./LoginBottomSheet.module.css";
import { authObservable, AuthService, setAuthToken } from "../../services/auth";
import { Router, useRouter } from "next/router";

const countries = [
  {
    code: "IN",
    name: "India",
    flag: "https://static.uacdn.net/thumbnail/country-flag-icons/in.png",
    dialCode: "+91",
    digits: 10,
  },
  {
    code: "US",
    name: "USA",
    flag: "https://static.uacdn.net/thumbnail/country-flag-icons/us.png",
    dialCode: "+1",
    digits: 10,
  },
  {
    code: "GB",
    name: "UK",
    flag: "https://static.uacdn.net/thumbnail/country-flag-icons/gb.png",
    dialCode: "+44",
    digits: 10,
  },
  {
    code: "AE",
    name: "UAE",
    flag: "https://static.uacdn.net/thumbnail/country-flag-icons/ae.png",
    dialCode: "+971",
    digits: 10,
  },
  {
    code: "AU",
    name: "Australia",
    flag: "https://static.uacdn.net/thumbnail/country-flag-icons/au.png",
    dialCode: "+61",
    digits: 10,
  },
];

const LoginBottomSheet = ({ isOpen, onClose, onCancel }) => {
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [stage, setStage] = useState("phoneNumber"); // 'phoneNumber' or 'otpSent'
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [resendTimer, setResendTimer] = useState(60); // 60 seconds countdown
  const [otp, setOtp] = useState(["", "", "", ""]); // State to manage the OTP inputs
  const [isVerifying, setIsVerifying] = useState(false); // State to manage the verifying state
  const [otpCode, setOtpCode] = useState("");

  const otpRef = useRef(otpCode);

  const router = useRouter();

  useEffect(() => {
    otpRef.current = otpCode;
  }, [otpCode]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [resendTimer]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setPhoneNumber(""); // Clear phone number when country changes
    setErrorMessage(""); // Clear any previous error messages
    setIsDropdownOpen(false);
  };

  const sendOtp = async() => {
    let payload = {
      phoneNumber: phoneNumber.slice(-10),
      browserId: localStorage.getItem("FPJS").replace(/"/g, ""),
    }

    let response = await AuthService.sendOTP(payload);

    if(response.success){
      setStage("otpSent");
      toast.success("OTP Sent SuccessFully");
    }else{
      toast.error("Failed To Send OTP");
    }
  }

  const resendOTP = async() => {
    setResendTimer(60);
    sendOtp();
  }

  const verifyOtp = async() => {    
    if(otpRef.current.length !== 4){ 
      return;
    }
    setIsVerifying(true);
    toast.loading("Verifying OTP...", { id: "otp-verification" });

    let payload = {
      phoneNumber: phoneNumber.slice(-10),
      otp: otpRef.current,
    };

    let response = await AuthService.verifyOTP(payload);

    setIsVerifying(false);

    if(response.success){
      toast.dismiss("otp-verification");
      toast.success("OTP Verified Successfully!");
      // router.push("/dashboard");
      authObservable.userLoggedIn();
      onClose();
      setTimeout(() => {
        router.reload();        
      }, 10)
    }else{
      toast.dismiss("otp-verification");
      toast.error("Invalid OTP!");
    }
  }

  const handleOtpChange = async (e, index) => {
    const { value } = e.target;
    if (/^\d$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
  
      if (value !== "" && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
  
      if (newOtp.join("").length === 4 && !isVerifying) {
        setOtpCode(newOtp.join(""));
        setTimeout(() => {
          verifyOtp();
        })
      }
    }
  };
  

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    let error = "";
  
    if (selectedCountry.code === "IN" && numericInput.length > 10) {
      error = "Mobile number cannot exceed 10 digits";
    } else if (
      selectedCountry.code === "IN" &&
      numericInput.length > 0 &&
      /^[0-4]/.test(numericInput)
    ) {
      error = "Please enter a valid mobile number";
    } else if (numericInput !== input) {
      error = "Please enter only numbers";
    }
  
    setPhoneNumber(numericInput);
  
    // Only show the toast if the error message has changed
    if (error !== errorMessage) {
      setErrorMessage(error);
      if (error) {
        toast.error(error);
      }
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`${styles.loginBottomSheet} ${isOpen ? styles.open : ""}`}>
      <div className={styles.header}>
        <h3>Login</h3>
        <button onClick={onCancel} className={styles.closeButton}>
          <svg
            fill="none"
            strokeWidth="1.5"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className={styles.closeIcon}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div className={styles.content}>
        {stage === "phoneNumber" && (
          <div className={styles.inputContainer}>
            <div className={styles.numberOTPWrapper}>
              <div className={styles.phoneNumberInputContainer}>
                <div className={styles.countryCodeSelect}>
                  <img
                    src={selectedCountry.flag}
                    alt={selectedCountry.name}
                    className={styles.countryFlag}
                  />
                  <div className={styles.selectWrapper}>
                    <div
                      className={styles.countrySelect}
                      onClick={toggleDropdown}
                    >
                      <span>{selectedCountry.dialCode}</span>
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 80 80"
                        className={styles.arrowIcon}
                      >
                        <path
                          d="M39.8 52.033c-0.003 0-0.008 0-0.012 0-0.687 0-1.308-0.28-1.755-0.733l-20-20c-0.49-0.458-0.796-1.108-0.796-1.829 0-1.381 1.119-2.5 2.5-2.5 0.721 0 1.371 0.306 1.828 0.794l0.001 0.001 18.233 18.233 18.233-18.233c0.445-0.416 1.045-0.671 1.704-0.671 1.381 0 2.5 1.119 2.5 2.5 0 0.659-0.255 1.259-0.672 1.706l0.001-0.001-20 20c-0.447 0.453-1.068 0.733-1.755 0.733-0.004 0-0.008 0-0.012 0h0.001z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    {isDropdownOpen && (
                      <div className={styles.dropdownMenu}>
                        <ul>
                          {countries.map((country) => (
                            <li
                              key={country.code}
                              onClick={() => handleCountryChange(country)}
                            >
                              <img
                                src={country.flag}
                                alt={country.name}
                                className={styles.countryFlag}
                              />
                              <span>{country.name}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <input
                  type="tel"
                  placeholder="Enter your mobile number"
                  className={`${styles.phoneNumberInput} ${
                    errorMessage ? styles.error : ""
                  }`}
                  maxLength={selectedCountry.code === "IN" ? 10 : 15}
                  minLength="3"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  autoComplete="tel-national"
                />
              </div>
              <span className={styles.errorMessage}>{errorMessage}</span>
              <button
                className={styles.sendOTPButton}
                disabled={
                  !!errorMessage ||
                  phoneNumber.length < (selectedCountry.code === "IN" ? 10 : 1)
                }
                onClick={() => sendOtp()}
              >
                SEND OTP
              </button>
              <div className={styles.loginTerms}>
                <p>
                  By continuing, I agree to the{" "}
                  <span>
                    <a href="/terms-condition">Terms of Use</a>
                  </span>{" "}
                  &amp;
                  <span>
                    {" "}
                    <a href="/privacy-policy"> Privacy Policy</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
        {stage === "otpSent" && (
          <div className={styles.otpWrapper}>
            <p className={styles.otpMessage}>
              We've sent an OTP to your registered mobile number
            </p>
            <div className={styles.disabledInputContainer}>
              <div className={styles.disabledInput}>
                <div className={styles.selectWrapper2}>
                  <img
                    src={selectedCountry.flag}
                    alt={selectedCountry.name}
                    className={styles.countryFlag}
                  />
                  <span>{selectedCountry.dialCode}</span>
                </div>
                <input
                  type="tel"
                  className={styles.disabledInputField}
                  value={phoneNumber}
                  disabled
                />
                <a
                  href="#"
                  className={styles.editLink}
                  onClick={() => setStage("phoneNumber")}
                >
                  <h6>Edit</h6>
                </a>
              </div>
            </div>
            <div className={styles.otpContainer}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="tel"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  disabled={isVerifying} // Disable inputs during verification
                />
              ))}
            </div>
            <button
              type="button"
              className={styles.verifyButton}
              disabled={isVerifying}
              onClick={() => verifyOtp()}
            >
              {isVerifying ? "Verifying..." : "Verify OTP"}
            </button>
            <div className={styles.resendWrapper}>
              {resendTimer > 0 ? (
                <span className={styles.resendLink} style={{ color: "#ccc" }}>
                  Resend OTP in {resendTimer} sec(s)
                </span>
              ) : (
                <a
                  href="#"
                  className={styles.resendLink}
                  onClick={() => resendOTP()}
                >
                  Resend OTP
                </a>
              )}
            </div>
          </div>
        )}

        <div className={styles.bottomLink}>
          <p>
            <span className={styles.loginTerms}>
              Having trouble? Visit our{" "}
              <a href="https://unacademy.com/helpcenter">Help center</a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginBottomSheet;
