import { useEffect, useState } from "react";
import LoginBottomSheet from "./LoginBottomSheet";
import { authObservable } from "../../services/auth";
import { useRouter } from "next/router";

export default function LoginComponent() {
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

    const handleOpenBottomSheet = () => setIsBottomSheetOpen(true);
    // const handleCloseBottomSheet = () => setIsBottomSheetOpen(false);

    const router = useRouter();

    const handleCloseBottomSheet = () => {
        setIsBottomSheetOpen(false);
        // Add a slight delay before reloading to ensure the state is updated
        setTimeout(() => {
            router.reload();
        }, 100);
    };

    const handleCancel = () => {
        setIsBottomSheetOpen(false);
    };

    useEffect(() => {
        authObservable.getAuth().subscribe((data) => {
            if (data.show) {
                handleOpenBottomSheet();
            } else {
                handleCloseBottomSheet();
            }
        }); 
    }, [])

    return (
        <LoginBottomSheet isOpen={isBottomSheetOpen} onClose={handleCloseBottomSheet} onCancel={handleCancel}/>
    )
}
